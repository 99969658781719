import React, {useState} from 'react';
import './main.scss';
import './mainAdaptive.scss';
import {useRecoilState} from "recoil";
import {userState} from "../../../store/user";
import Preloader from "../../../components/Preloader";
// @ts-ignore
import Range from 'react-range-progress';
import Button from "../../../components/Button";

const Index = ({setType}: any) => {

    const [user]: any = useRecoilState(userState);
    const [count, setCount] = useState(4);

    const [val, setVal] = useState(50)

    const onRangeChanged = (value: any) => {
        setVal(value)
    }

    return (
        <div className="wrapper">
            {user ?
                <>
                    <h2 className="userName">{user ? 'Hello ' + user.lastName[0].toUpperCase() + user.lastName.slice(1, user.lastName.length) : ''}</h2>
                    <div className="dashboardMain">
                        <div className="Upcoming">
                            <div className="Upcoming__total">
                                <div className="Upcoming__box">
                                    <h2>due in next 30 days</h2>
                                    <span>$10,000</span>
                                </div>
                                <div className="Upcoming__box">
                                    <h2>total outstanding</h2>
                                    <span>$100,000</span>
                                </div>
                            </div>
                            <div className="Upcoming__payments">
                                <h2>Upcoming payments</h2>
                                <div className="Upcoming__payments-dates">
                                    {user?.upcomingPayments ? user.upcomingPayments.slice(0, count).map((el: any, idx: number) => (
                                            <div key={idx}>
                                                <h3>{el.sum}$</h3>
                                                <span>{el.date}</span>
                                            </div>
                                        ))
                                        : ''
                                    }
                                </div>
                                <a onClick={() => setType('history')} className="viewAll">View All</a>
                            </div>
                        </div>
                        <div className="MyFunds">
                            <h2 className="title">My funds</h2>
                            <div className="progress">
                                <div className="texts">
                                    <span>Used</span>
                                    <span>available</span>
                                </div>
                                <Range
                                    value={val}
                                    hideThumb={true}
                                    trackColor={{
                                        r: 15,
                                        g: 91,
                                        b: 59,
                                        a: 0.1,
                                    }}
                                    height={32}
                                    width="100%"
                                    onChange={(e: any) => onRangeChanged(e)}
                                />
                                <div className="Buttons">
                                    <Button type={1} text={"CONNECT BANK"}/>
                                    <Button type={1} text={"CONNECT Tax"}/>
                                </div>
                                <span className="infoTitle">* Please sync your accounts to see your available credit line.</span>
                            </div>

                        </div>
                    </div>
                </>

                : <Preloader/>
            }
        </div>
    );
};

export default Index;