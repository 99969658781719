import React from 'react';

const EmailPurple = () => {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_465_209)">
                <path
                    d="M38.6164 7.16688C38.4536 7.1501 38.2895 7.1501 38.1267 7.16688H4.68225C4.4679 7.17018 4.25497 7.20232 4.04919 7.26243L21.3089 24.4505L38.6164 7.16688Z"
                    fill="url(#paint0_linear_465_209)"/>
                <path
                    d="M40.3842 8.82715L22.9931 26.1466C22.5455 26.5915 21.94 26.8413 21.3089 26.8413C20.6778 26.8413 20.0723 26.5915 19.6247 26.1466L2.38891 8.95854C2.33592 9.15328 2.30783 9.35395 2.3053 9.55576V33.4446C2.3053 34.0782 2.55698 34.6858 3.00499 35.1338C3.45299 35.5819 4.06061 35.8335 4.69419 35.8335H38.1386C38.7722 35.8335 39.3798 35.5819 39.8278 35.1338C40.2758 34.6858 40.5275 34.0782 40.5275 33.4446V9.55576C40.518 9.3069 40.4697 9.06106 40.3842 8.82715V8.82715ZM6.33058 33.4446H4.6703V31.7366L13.3539 23.1246L15.0381 24.8088L6.33058 33.4446ZM38.1147 33.4446H36.4425L27.735 24.8088L29.4192 23.1246L38.1028 31.7366L38.1147 33.4446Z"
                    fill="url(#paint1_linear_465_209)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_465_209" x1="38.6164" y1="24.4505" x2="2.24898" y2="12.7495"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#474CFB"/>
                    <stop offset="1" stopColor="#8A36C0"/>
                </linearGradient>
                <linearGradient id="paint1_linear_465_209" x1="40.5275" y1="35.8335" x2="-1.65801" y2="26.2216"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#474CFB"/>
                    <stop offset="1" stopColor="#8A36C0"/>
                </linearGradient>
                <clipPath id="clip0_465_209">
                    <rect width="43" height="43" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default EmailPurple;