import React, {useEffect, useState} from 'react';
import SelectorArrow from "../../shared/Header/lang/selectorArrow";
import {useRecoilState} from "recoil";
import {BurgerState, languageState} from "../../store/states";
import USA from "../../assets/images/USA.png";
import Mexico from "../../assets/images/Mexico.png";
import {useTranslation} from "react-i18next";
import './LangSelect.scss';


const Index = () => {

    const [language, setLanguage] = useRecoilState(languageState);

    const [burger, setBurger] = useRecoilState(BurgerState);

    const [selectState, setSelectState] = useState(false);
    const Langs = [
        {lang: 'English', img: USA},
        {lang: 'Espanol', img: Mexico}
    ];

    const {t, i18n} = useTranslation();
    const [lang, setLang] = useState(USA);

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang)
    };

    useEffect(() => {
        const localLang = localStorage.getItem("i18nextLng");
        if (localLang) {
            setLang(localLang === 'Espanol' ? Mexico : USA)
        }
    }, []);
    return (
        <div onClick={() => {
            setSelectState(prevState => !prevState)
        }} className={`selectLang ${burger ? 'on' : ''}`}>
            <img className="icon" src={lang} alt={language}/>
            <div className={`values ${selectState ? '' : 'valueDisabled'}`}>
                {Langs.map((el: any, index: number) => (
                    <div key={index} onClick={() => {
                        setLang(el.img);
                        setLanguage(el.lang);
                        changeLanguage(el.lang)
                    }}>
                        <img className="icon" src={el.img} alt=""/>
                        <p>{el.lang}</p>
                    </div>
                ))}
            </div>
            <SelectorArrow state={selectState}/>
        </div>
    );
};

export default Index;