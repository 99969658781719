import React, {FC} from 'react';
import {useLocation, Navigate} from "react-router-dom";

interface IAuthed {
    children: any
}

const Authed: FC<IAuthed> = ({children}) => {
    const location = useLocation();

    if (localStorage.getItem("token")) {
        return <Navigate to={"/"} state={{from: location}}/>
    }

    return children
};

export default Authed;