import React from 'react';
import './joinModal.scss';
import New1 from '../../assets/images/new1.png';
import New2 from '../../assets/images/new2.png';
import New3 from '../../assets/images/new3.png';
import {useTranslation} from "react-i18next";
import Button from '../../components/Button';
import {useRecoilState} from "recoil";
import {HeaderHeightState} from "../../store/states";
import {Link} from "react-router-dom";

const JoinModal = () => {
    const [headerHeight] = useRecoilState(HeaderHeightState);
    const {t} = useTranslation();
    return (
        <section style={{minHeight: `calc(100vh - ${headerHeight}px)`}} className="joinModal">
            <div className="container">
                <h2 className="joinModal__title">{t('joinTitle')}</h2>
                <p className="joinModal__subtitle">{t('joinSubtitle')}</p>
                <p className="joinModal__text">In the meantime, check out some of our recent posts:</p>
                <div className="joinModal__row">
                    <div className="joinModal__card">
                        <div className="joinModal__card_img">
                            <img src={New1} alt=""/>
                        </div>
                        <div className="joinModal__card_content">
                            <h3 className="joinModal__card_title">{t('homeUpdatesCard1Title')}</h3>
                            <div className="joinModal__card_row">
                                <p className="joinModal__card_date">10.10.2022</p>
                                <Link to={"/news/1"}>
                                    <Button url={true} type={2} text={t('moreDetailsButton')}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="joinModal__card">
                        <div className="joinModal__card_img">
                            <img src={New2} alt=""/>
                        </div>
                        <div className="joinModal__card_content">
                            <h3 className="joinModal__card_title">{t('homeUpdatesCard2Title')}</h3>
                            <div className="joinModal__card_row">
                                <p className="joinModal__card_date">17.12.2022</p>
                                <Link to={"/news/2"}>
                                    <Button url={true} type={2} text={t('moreDetailsButton')}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="joinModal__card">
                        <div className="joinModal__card_img">
                            <img src={New3} alt=""/>
                        </div>
                        <div className="joinModal__card_content">
                            <h3 className="joinModal__card_title">{t('homeUpdatesCard3Title')}</h3>
                            <div className="joinModal__card_row">
                                <p className="joinModal__card_date">23.01.2023</p>
                                <Link to={"/news/3"}>
                                    <Button url={true} type={2} text={t('moreDetailsButton')}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default JoinModal;