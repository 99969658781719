import React, {useEffect, useRef, useState} from 'react';
import './HomeIntro.scss';
import Button from "../../../../components/Button";
import Iphone from "../../../../assets/images/Iphone.png";
import {useTranslation} from "react-i18next";

const Index = () => {
    const {t} = useTranslation();
    const ref: any = useRef();
    const [titleGrey, setTitleGrey] = useState(1);
    const [status, setStatus] = useState<any>(false);
    const [animateStarted, setAnimateStarted] = useState(false);

    const startAnimate = () => {
        ref.current.textContent = '';
        let text = t(`homeIntroTitleGrey${titleGrey}`);
        let i = 0;
        const animateHandler = () => {
            const a = () => {
                setTimeout(() => {
                    const localText = text.slice(0, i + 1);
                    ref.current.textContent = `${localText}`;
                    i++;
                    if (i <= text.length - 1) {
                        a()
                    }
                }, 2000 / text.length)
            };
            a();
            const b = () => {
                setTimeout(() => {
                    const localText = text.slice(0, i);
                    ref.current.textContent = `${localText}`;
                    i--;
                    if (i >= 0) {
                        b()
                    } else {
                        setTimeout(() => {
                            setStatus(!status)
                        }, 1500)
                    }
                }, 1000 / text.length)
            };
            setTimeout(() => {
                b()
            }, 3000)
        };
        animateHandler();
        if (titleGrey === 1) {
            setTitleGrey(2)
        } else if (titleGrey === 2) {
            setTitleGrey(3)
        } else if (titleGrey === 3) {
            setTitleGrey(4)
        } else {
            setTitleGrey(1)
        }
    };


    useEffect(() => {
        if (animateStarted) {
            startAnimate()
        } else {
            setTimeout(() => {
                startAnimate()
            }, 2000);
            setAnimateStarted(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <div id="solutions" className="homeIntro">
            <div className="container">
                <div className="homeIntro__main">
                    <div className="homeIntro__main_left">
                        <h3><span>{t('homeIntroSubtitle')}</span>🚀</h3>
                        <h2>
                            {`${t('homeIntroTitle')}`}<br/><span ref={ref} className={"animation-title"}/>
                        </h2>
                        <p className={"homeIntro__main_text"}>{t('homeIntroText')}</p>
                        <Button type={1} text={t('joinButton')}/>
                    </div>
                    <div className="homeIntro__main_right">
                        <img src={Iphone} alt="iphone"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;