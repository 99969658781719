import React from 'react';
import './investorsHome.scss';
import EmailPurple from "../../../../components/Icons/EmailIcon/EmailPurple";
import {useTranslation} from "react-i18next";

const HomeInvestors = () => {
    const {t} = useTranslation();

    return (
        <section className={"homeInvestors"}>
            <div className="container">
                <h2 className="homeInvestors__title">{t('homeInvestorsTitle')}</h2>
                <h3 className="homeInvestors__subtitle">{t('homeInvestorsSubtitle')}</h3>
                <p className="homeInvestors__text">{t('homeInvestorsText')}</p>
                <div className="homeInvestors__buttonParent">
                    <button onClick={() => {
                        let textField = document.createElement('textarea');
                        textField.innerText = 'ir@rapidpagos.com';
                        document.body.appendChild(textField);
                        textField.select();
                        document.execCommand('copy');
                        textField.remove();
                    }} className="homeInvestors__button">
                        <EmailPurple/>
                        ir@rapidpagos.com
                    </button>
                </div>
            </div>
        </section>
    );
};

export default HomeInvestors;