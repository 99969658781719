import React from 'react';
import './Card.scss';
import Button from "../Button";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

interface Interface {
    card: {
        title: string,
        desc: string,
        date: string,
        id: number,
        image: string
    }
}

const Index: React.FC<Interface> = ({card}) => {
    const {t} = useTranslation();
    return (
        <div className="Card">
            <div className="Card__imageBlock">
                <img src={card.image} alt={card.title} className="Card__imageBlock_img"/>
            </div>
            <h2>{card.title}</h2>
            <span className="desc">{card.desc}</span>
            <div className="Card__down">
                <span className="Card__down_title">{card.date}</span>
                <Link to={`/news/${card.id}`}>
                    <Button url={true} type={2} text={t('moreDetailsButton')}/>
                </Link>
            </div>
        </div>
    );
};

export default Index;