import React from 'react';
import './switch.scss';
import {useRecoilState} from "recoil";
import {BurgerState, themeState} from "../../store/states";

const Switch = () => {

    const [theme, setTheme] = useRecoilState(themeState);
    const [burger, setBurger] = useRecoilState(BurgerState);

    return (
        <div style={{background: `${theme === 'light' ? '#E8E8E8' : '#33285E'}`}} className={`switch ${burger ? 'on' : ''}`}
             onClick={() => {
                 setTheme(`${theme === 'dark' ? 'light' : 'dark'}`);
                 localStorage.setItem('theme', `${theme === 'dark' ? 'light' : 'dark'}`)
             }}>
            <div
                className={`switch__circle ${theme === 'light' ? "switch__circle-disabled" : "switch__circle-active"}`}>
                {theme === 'light'
                    ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.87082 1.68708V1V1.68708ZM7.87082 14.7416V14.0546V14.7416ZM14.0546 7.87082H14.7416H14.0546ZM1 7.87082H1.68708H1ZM12.3369 3.40479L13.3675 2.37416L12.3369 3.40479ZM2.37416 13.3675L3.40479 12.3369L2.37416 13.3675ZM2.37416 2.37416L3.40479 3.40479L2.37416 2.37416ZM12.3369 12.3369L13.3675 13.3675L12.3369 12.3369Z"
                            fill="url(#paint0_linear_490_36)"/>
                        <path
                            d="M7.87082 1.68708V1M7.87082 14.7416V14.0546M14.0546 7.87082H14.7416M1 7.87082H1.68708M12.3369 3.40479L13.3675 2.37416M2.37416 13.3675L3.40479 12.3369M2.37416 2.37416L3.40479 3.40479M12.3369 12.3369L13.3675 13.3675"
                            stroke="url(#paint1_linear_490_36)" strokeWidth="2" strokeLinecap="round"/>
                        <path
                            d="M7.87089 10.6192C9.38875 10.6192 10.6192 9.38875 10.6192 7.87089C10.6192 6.35303 9.38875 5.12256 7.87089 5.12256C6.35303 5.12256 5.12256 6.35303 5.12256 7.87089C5.12256 9.38875 6.35303 10.6192 7.87089 10.6192Z"
                            fill="url(#paint2_linear_490_36)" stroke="url(#paint3_linear_490_36)" strokeWidth="2"
                            strokeLinecap="round"/>
                        <defs>
                            <linearGradient id="paint0_linear_490_36" x1="14.7416" y1="14.7416" x2="-0.809216"
                                            y2="12.2381"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#474CFB"/>
                                <stop offset="1" stopColor="#8A36C0"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_490_36" x1="14.7416" y1="14.7416" x2="-0.809216"
                                            y2="12.2381"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#474CFB"/>
                                <stop offset="1" stopColor="#8A36C0"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_490_36" x1="10.6192" y1="10.6192" x2="4.39887"
                                            y2="9.61781"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#474CFB"/>
                                <stop offset="1" stopColor="#8A36C0"/>
                            </linearGradient>
                            <linearGradient id="paint3_linear_490_36" x1="10.6192" y1="10.6192" x2="4.39887"
                                            y2="9.61781"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#474CFB"/>
                                <stop offset="1" stopColor="#8A36C0"/>
                            </linearGradient>
                        </defs>
                    </svg>
                    : <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" fill="url(#paint0_linear_585_380)"
                              stroke="url(#paint1_linear_585_380)" strokeWidth="1.5"
                              d="M1 6.5C1 9.536 3.688 12 7 12C10.2736 11.9974 13 9.36 13 6.72C12.04 7.16 9.16 8.04 7.24 6.28C5.32 4.52 6.28 1.88 6.76 1C3.88 1 1 3.4992 1 6.5Z"/>
                        <defs>
                            <linearGradient id="paint0_linear_585_380" x1="13" y1="12" x2="-0.515013" y2="9.62643"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#474CFB"/>
                                <stop offset="1" stopColor="#8A36C0"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_585_380" x1="13" y1="12" x2="-0.515013" y2="9.62643"
                                            gradientUnits="userSpaceOnUse">
                                <stop stopColor="#474CFB"/>
                                <stop offset="1" stopColor="#8A36C0"/>
                            </linearGradient>
                        </defs>
                    </svg>
                }
            </div>
        </div>
    );
};

export default Switch;