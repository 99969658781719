import React, {FC} from 'react';
import {useLocation, Navigate} from "react-router-dom";

interface IRequireAuth {
    children: any
}

const RequireAuth: FC<IRequireAuth> = ({children}) => {
    const location = useLocation();

    if (!localStorage.getItem("token")) {
        return <Navigate to={"/login"} state={{from: location}}/>
    }

    return children
};

export default RequireAuth;