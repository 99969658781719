import React from 'react';
import "./login.scss";
import LoginInput from "../../components/LoginInput";
import Button from "../../components/Button";
import {Link, useNavigate} from "react-router-dom";
import LoginSocialMedia from "../../components/LoginSocialMedia";
import {getUserData, postLogin} from "../../utils/firebase";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {useRecoilState} from "recoil";
import {userState} from "../../store/user";
import {child, get, getDatabase, ref} from "firebase/database";
import {useTranslation} from "react-i18next";
import {modal} from "../../store/states";

const Login = () => {

    const [currentUser, setCurrentUser]: any = useRecoilState(userState);
    const [modalState, setModalState] = useRecoilState(modal)
    const navigate = useNavigate();

    const navigateToDashboard = () => {
        navigate('/dashboard');
    };


    const loginHandler = (e: any) => {
        e.preventDefault();
        postLogin(e.target[0].value, e.target[1].value)
            .then((response: any) => {
                localStorage.setItem("token", response?.accessToken);
                localStorage.setItem("uid", response?.uid);
                getUserData(response.uid, setCurrentUser, setModalState, navigateToDashboard);
            }).catch(() => {
            console.log('Error!')
        })
    };

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
        } else {
        }
    });

    const {t} = useTranslation();

    return (
        <>
            <h2 className="login__title">{t('welcome')}</h2>
            <form onSubmit={(e) => loginHandler(e)} className="login__form">
                <LoginInput name={'email'} type={true} placeholder={t("emailAddress")}/>
                <LoginInput name={"password"} type={false} placeholder={t('password')}/>
                <Link className="login__form_changePass" to={"/"}>{t('forgotPassword')}?</Link>
                <Button type={2} text={t('logIn')}/>
            </form>
            {/*<p className="login__alt">{t('signUpAlt')}</p>*/}
            {/*<LoginSocialMedia/>*/}
            <div className="login__action">
                <p className="login__action_text">{t('dntHaveAcc')}?</p>
                <Link to={"/signup"} className="login__action_text">{t('signUp')}</Link>
            </div>
        </>
    );
};

export default Login;