import React from 'react';
import './Button.scss';

interface Interface {
    type: number,
    text: string,
    url?: boolean
}

const Index: React.FC<Interface> = ({type, text, url}) => {
    return (
        <button className={`button ${type === 1 ? "button1" : 'button2'} ${url ? 'urlButton' : ''}`}>
            <span>{text}</span>
            {url ?
                <div className="button__childRight">
                    <svg className="svg" width="33" height="8" viewBox="0 0 33 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM32.3536 4.35356C32.5488 4.15829 32.5488 3.84171 32.3536 3.64645L29.1716 0.464469C28.9763 0.269206 28.6597 0.269206 28.4645 0.464468C28.2692 0.659731 28.2692 0.976313 28.4645 1.17158L31.2929 4L28.4645 6.82843C28.2692 7.02369 28.2692 7.34027 28.4645 7.53554C28.6597 7.7308 28.9763 7.7308 29.1716 7.53554L32.3536 4.35356ZM1 4.5L32 4.5L32 3.5L1 3.5L1 4.5Z"
                            fill="white"/>
                    </svg>
                </div>
                : ""
            }
        </button>
    );
};

export default Index;