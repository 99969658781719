import React from 'react';
import {useRecoilState} from "recoil";
import {BurgerState} from "../../store/states";

const Burger = () => {

    const [burger, setBurger] = useRecoilState(BurgerState);

    return (
        <div className={`Burger ${burger ? 'active' : ''}`} onClick={() => {
            setBurger((prevState) => !prevState)
        }}>
            <div className="Burger__up"/>
            <div className="Burger__down"/>
        </div>
    );
};

export default Burger;