import React from 'react';
import {Outlet} from 'react-router-dom';
import Footer from "../../shared/Footer";

const Layout = () => {
    return (
        <>
            <Outlet/>
            <Footer/>
        </>
    );
};

export default Layout;