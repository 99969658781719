import {atom} from 'recoil';

export const languageState = atom({
    key: 'languageState',
    default: 'Espanol',
});


export const themeState = atom({
    key: 'themeState',
    default: 'dark',
});

export const BurgerState = atom({
    key: 'burgerState',
    default: false,
});

export const HeaderHeightState = atom({
    key: 'headerHeightState',
    default: 0,
});

export const modal = atom({
    key: 'modal',
    default: '',
});