import {initializeApp} from "firebase/app";
import {child, get, getDatabase, ref, set} from "firebase/database";
import {
    createUserWithEmailAndPassword,
    getAuth,
    signInWithEmailAndPassword,
    signOut,
    updateProfile
} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA4wsTaVy0mbAfo8NetPwRxRS1UH3f_NGA",
    authDomain: "model-fastness-364918.firebaseapp.com",
    projectId: "model-fastness-364918",
    storageBucket: "model-fastness-364918.appspot.com",
    messagingSenderId: "201843945260",
    appId: "1:201843945260:web:6630e8350447c8cc500689",
    measurementId: "G-0T4DDXCV42",
    databaseURL: "https://model-fastness-364918-default-rtdb.europe-west1.firebasedatabase.app",
};

const app = initializeApp(firebaseConfig);

export const writeUserData = (userId, firstName, lastName, companyName, phone, email) => {
    const db = getDatabase();
    const newUser = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        companyName: companyName,
        phone: phone,
        creditLimit: 0,
        userId: userId,
        city: false,
        state: false,
        address: false,
        industry: false,
        taxId: false,
        confirmation: false
    };
    set(ref(db, 'users/' + userId), newUser);
};

export const postRegisterUser = (firstName, lastName, companyName, email, phone, password) => {
    const auth = getAuth();
    return createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            const user = userCredential.user;
            await writeUserData(user.uid, firstName, lastName, companyName, phone, email);
            return user
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage)
        });
};


export const postLogOut = (onSuccess) => {
    const auth = getAuth();
    signOut(auth).then((response) => {
        onSuccess('');
        localStorage.removeItem("token");
        localStorage.removeItem("uid")
    }).catch((error) => {
        // An error happened.
    });
};

export const postLogin = (email, password) => {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            return userCredential.user
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
};

export const getUserData = (ui, setCurrentUser, setModalState, navigate) => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `users/${ui}`)).then((snapshot) => {
        if (snapshot.exists()) {
            setCurrentUser(snapshot.val());
            return snapshot
        }
    }).then((snapshot) => {
        if (snapshot.val().confirmation) {
            setModalState('Successful login!');
            setTimeout(() => {
                navigate()
            }, 0)
        } else {
            setModalState('Your Account not confirmed');
            postLogOut(setCurrentUser)
        }
    }).catch((error) => {
        console.error(error);
    });
};

// export const postUpdate = () => {
//     const auth = getAuth();
//     updateProfile(auth.currentUser, {
//         displayName: "Huesos",
//         hui: '20cm'
//     }).then((response) => {
//         console.log(response)
//     }).catch((error) => {
//         console.log(error)
//     });
// };
//
// export const showUser = () => {
//     const auth = getAuth();
//     const user = auth.currentUser;
//     if (user !== null) {
//         user.providerData.forEach((profile) => {
//             console.log("Sign-in provider: " + profile.providerId);
//             console.log("  Provider-specific UID: " + profile.uid);
//             console.log("  Name: " + profile.displayName);
//             console.log("  Email: " + profile.email);
//             console.log("  Photo URL: " + profile.photoURL);
//         });
//     }
// };