import * as React from 'react';
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({borderTop: `1px solid rgba(15, 59, 91, 0.6)`}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{
            fontSize: '0.9rem',
            color: `${localStorage.getItem("theme") === 'light' ? 'rgba(15, 59, 91, 0.6)' : 'rgba(218, 245, 253, 0.6)'}`
        }}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor: "transparent",
    padding: "10px 0",
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(15, 59, 91, 0.6)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const {t} = useTranslation();

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography component="h2">{t('homeFaqAccordion1Title')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component="p">{t('homeFaqAccordion1Text')}</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography component="h2">{t('homeFaqAccordion2Title')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component="p">{t('homeFaqAccordion2Text')}</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography component="h2">{t('homeFaqAccordion3Title')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component="p">{t('homeFaqAccordion3Text')}</Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
