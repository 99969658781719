import React from 'react';
import HomeIntro from "./Section/HomeIntro";
import HomeBenefits from "./Section/HomeBenefits";
import HomeSubmit from "./Section/HomeSubmit";
import HomeFaq from "./Section/Faq";
import HomeUpdates from "./Section/HomeUpdates";
import HomeInvestors from "./Section/Investors";
import Framer from "../../components/Framer/Framer";



const Home = () => {
    return (
        <>
            <Framer content={<HomeIntro/>}/>
            <Framer content={<HomeSubmit/>}/>
            <Framer content={<HomeBenefits/>}/>
            <Framer content={<HomeFaq/>}/>
            <Framer content={<HomeUpdates/>}/>
            <Framer content={<HomeInvestors/>}/>
        </>
    );
};

export default Home;