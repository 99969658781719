import React from 'react';

const Index = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.643 4.93659C22.808 5.30659 21.911 5.55659 20.968 5.66959C21.941 5.08738 22.669 4.17105 23.016 3.09159C22.1019 3.63458 21.1014 4.01678 20.058 4.22159C19.3564 3.47245 18.4271 2.97591 17.4143 2.80906C16.4016 2.64221 15.3621 2.81438 14.4572 3.29884C13.5524 3.7833 12.8328 4.55295 12.4102 5.48829C11.9875 6.42363 11.8855 7.47234 12.12 8.47159C10.2677 8.37858 8.45564 7.89714 6.80144 7.05849C5.14723 6.21985 3.68785 5.04275 2.51801 3.60359C2.11801 4.29359 1.88801 5.09359 1.88801 5.94559C1.88757 6.71258 2.07644 7.46783 2.43789 8.14432C2.79934 8.82081 3.32217 9.39763 3.96001 9.82359C3.22029 9.80005 2.49688 9.60017 1.85001 9.24059V9.30059C1.84994 10.3763 2.22204 11.419 2.90319 12.2516C3.58434 13.0842 4.53258 13.6555 5.58701 13.8686C4.9008 14.0543 4.18135 14.0817 3.48301 13.9486C3.78051 14.8742 4.36001 15.6836 5.14038 16.2635C5.92075 16.8434 6.86293 17.1648 7.83501 17.1826C6.18484 18.478 4.1469 19.1807 2.04901 19.1776C1.67739 19.1777 1.30609 19.156 0.937012 19.1126C3.06649 20.4818 5.54535 21.2084 8.07701 21.2056C16.647 21.2056 21.332 14.1076 21.332 7.95159C21.332 7.75159 21.327 7.54959 21.318 7.34959C22.2293 6.69056 23.0159 5.87448 23.641 4.93959L23.643 4.93659Z"
                fill="white"/>
        </svg>

    );
};

export default Index;