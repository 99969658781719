import React, {useState} from 'react';
import {userState} from "../../store/user";
import './index.scss';
import Main from "./main";
import Borrow from "./borrow/borrow";
import History from "./history/history";


const Dashboard = () => {

    const [type, setType] = useState('dashboard');

    const pageType = () => {
        switch (type) {
            case "dashboard":
                return <Main setType={setType}/>
            case "borrow":
                return <Borrow/>
            case "history":
                return <History/>
            default:
                return <Main setType={setType}/>
        }
    }

    const classType = (value: string) => {
        if (type === value) {
            return 'active'
        } else {
            return ''
        }
    }

    const changeType = (type: string) => {
        setType(type)
    }

    return (
        <>
            <div className="dashboard">
                <div className="container">
                    <nav className="dashboard__switches">
                        <a className={`${classType("dashboard")}`} onClick={() => changeType('dashboard')}>
                            <span>Dashboard</span>
                        </a>
                        <a className={`${classType("borrow")}`} onClick={() => changeType('borrow')}>
                            <span>Borrow</span>
                        </a>
                        <a className={`${classType("history")}`} onClick={() => changeType('history')}>
                            <span>History</span>
                        </a>
                    </nav>
                </div>
            </div>
            <div className='dashboard__tables'>
                <div className="container">
                    {
                        pageType()
                    }
                </div>
            </div>
        </>
    );
};

export default Dashboard;