import React from 'react';
import Fade from 'react-reveal/Fade';

const Framer = ({content}) => {
    return (
        <Fade bottom>
            {content}
        </Fade>

    );
};

export default Framer;