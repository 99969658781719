import React from 'react';
import './loginLayout.scss';
import {useRecoilState} from "recoil";
import {HeaderHeightState} from "../../store/states";
import {Outlet} from "react-router";

const LoginLayout = () => {
    const [headerHeight] = useRecoilState(HeaderHeightState);
    return (
        <>
            {headerHeight ?
                <div style={{minHeight: `calc(100vh - ${headerHeight}px)`}} className={"loginLayout"}>
                    <div className="loginLayout__box">
                        <Outlet/>
                    </div>
                </div>
                : ''
            }
        </>
    );
};

export default LoginLayout;