import React from 'react';

const Index = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_465_198)">
                <path
                    d="M25.1456 4.6664C25.0396 4.65547 24.9327 4.65547 24.8267 4.6664H3.04894C2.90936 4.66855 2.77071 4.68948 2.63672 4.72862L13.8756 15.9208L25.1456 4.6664Z"
                    fill="white"/>
                <path
                    d="M26.2968 5.74805L14.9723 17.0258C14.6809 17.3155 14.2866 17.4782 13.8757 17.4782C13.4647 17.4782 13.0705 17.3155 12.779 17.0258L1.55567 5.8336C1.52116 5.96041 1.50287 6.09108 1.50122 6.22249V21.778C1.50122 22.1906 1.66511 22.5863 1.95683 22.878C2.24856 23.1697 2.64422 23.3336 3.05678 23.3336H24.8346C25.2471 23.3336 25.6428 23.1697 25.9345 22.878C26.2262 22.5863 26.3901 22.1906 26.3901 21.778V6.22249C26.3839 6.06045 26.3524 5.90036 26.2968 5.74805ZM4.12233 21.778H3.04122V20.6658L8.69567 15.058L9.79233 16.1547L4.12233 21.778ZM24.819 21.778H23.7301L18.0601 16.1547L19.1568 15.058L24.8112 20.6658L24.819 21.778Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_465_198">
                    <rect width="28" height="28" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default Index;