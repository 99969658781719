import React from 'react';
import Burger from "./burger";
import {useRecoilState} from "recoil";
import {BurgerState} from "../../store/states";
import './burger.scss';
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {postLogOut} from "../../utils/firebase";
import {userState} from "../../store/user";

const BurgerMenu = () => {

    const [burger, setBurger] = useRecoilState(BurgerState);

    const [currentUser, setCurrentUser] = useRecoilState(userState);

    const navigate = useNavigate();
    const location = useLocation();
    const clickHrefHandler = () => {
        if (location.pathname.includes("signup") || location.pathname.includes("login")) {
            navigate('/')
        }
        setBurger(!burger)
    };

    const {t} = useTranslation();

    const logOutHandler = () => {
        postLogOut(setCurrentUser)
    };

    return (
        <div className="BurgerMenu">
            <Burger/>
            <Fade right when={burger}>
                <div className={burger ? 'BurgerNavbar' : 'none'}>
                    {localStorage.getItem("token") && <Link className="link" to={"/dashboard"}>{t('dashboard')}</Link>}
                    <a className="link" onClick={clickHrefHandler} href={"#solutions"}>{t('menuItem1')}</a>
                    <a className="link" onClick={clickHrefHandler} href={"#products"}>{t('menuItem2')}</a>
                    <a className="link" onClick={clickHrefHandler} href={"#learn"}>{t('menuItem3')}</a>
                    <div className="BurgerNavbar__buttons">
                        {localStorage.getItem("token")
                            ? <a onClick={logOutHandler} className="SignOutBurger">{t('logout')}</a>
                            : <>
                                <Link onClick={()=> setBurger(false)} to="/login">{t('logIn')}</Link>
                                <Link onClick={()=> setBurger(false)} to="/signup">{t('signUp')}</Link>
                            </>
                        }
                    </div>
                </div>
            </Fade>
            <div onClick={() => setBurger(false)} className={`blur ${burger ? '' : 'disableBurger'}`}/>
        </div>
    );
};

export default BurgerMenu;