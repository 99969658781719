import React, {FC, useRef} from 'react';
import './loginInput.scss'

interface ILoginInput {
    type: boolean,
    placeholder: string,
    name: string,
    onChange?: any
}

const LoginInput: FC<ILoginInput> = ({type, placeholder, name, onChange}) => {
    const passInputRef: any = useRef();
    const showPasswordHandler = () => {
        passInputRef.current.type = 'text';
    };
    const hidePasswordHandler = () => {
        passInputRef.current.type = 'password';
    };
    return (
        <>
            {type
                ?
                <input required={true} name={name} onChange={onChange} className="loginInput" placeholder={placeholder} type={'text'}/>
                : <div className="loginInput__password">
                    <input required={true} name={name} minLength={6} ref={passInputRef} className="loginInput" placeholder={placeholder}
                           type={'password'}/>
                    <svg onMouseUp={hidePasswordHandler} onMouseDown={showPasswordHandler}
                         viewBox="0 0 22 13" fill="none" width="22" height="13" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20.1245 4.309C20.2359 4.38656 20.3269 4.4899 20.3897 4.61022C20.4526 4.73054 20.4854 4.86426 20.4854 5C20.4854 5.13574 20.4526 5.26946 20.3897 5.38978C20.3269 5.5101 20.2359 5.61344 20.1245 5.691C18.5595 6.836 15.0625 9 11.0005 9C6.9385 9 3.4415 6.836 1.8765 5.691C1.7651 5.61344 1.67411 5.5101 1.61128 5.38978C1.54844 5.26946 1.51562 5.13574 1.51562 5C1.51562 4.86426 1.54844 4.73054 1.61128 4.61022C1.67411 4.4899 1.7651 4.38656 1.8765 4.309C3.4415 3.164 6.9385 1 11.0005 1C15.0625 1 18.5595 3.164 20.1245 4.309V4.309Z"
                            stroke="#AB92EB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M11.0005 9V12" stroke="#AB92EB" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M18.0005 7L20.0005 9.5" stroke="#AB92EB" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M15.0005 8.5L16.0005 11.5" stroke="#AB92EB" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6.00049 11.5L7.00049 8.5" stroke="#AB92EB" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M4.00049 7L2.00049 9.5" stroke="#AB92EB" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </div>
            }
        </>
    );
};

export default LoginInput;