import React, {useEffect} from 'react';
import './modal.scss';
import {useRecoilState} from "recoil";
import {modal} from "../../store/states";

const Index = () => {

    const [modalState, setModal] = useRecoilState(modal)

    useEffect(() => {
        setTimeout(() => {
            setModal('')
        }, 2000)
    }, [modalState])


    return (
        <>
            {modalState ?
                <div className={`none on`}>
                    {modalState.split('/')[0]}
                    {modalState.split('/')[1]}
                </div>
                : ''
            }
        </>

    );
};

export default Index;