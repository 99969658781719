import React from 'react';
import Pre from './preload.gif';

const Index = () => {
    return (
        <div style={{width: '100%', height: '32px', display: 'flex', justifyContent: 'center'}}>
            <img src={Pre} alt=""/>
        </div>
    );
};

export default Index;