import React, {FC} from 'react';
import './header.scss';
import {Link, useLocation, useNavigate} from "react-router-dom";
import GradientLogo from "../../components/Logo/gradientLogo";
import {useRecoilState} from "recoil";
import {themeState} from "../../store/states";
import Switch from "../../components/Switch";
import {useTranslation} from "react-i18next";
import DarkLogo from "../../components/Logo/DarkLogo";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import LangSelect from "../../components/LangSelect";
import {userState} from "../../store/user";
import {postLogOut} from "../../utils/firebase";
import LogoWhite from "../../components/Logo/LogoWhite";


interface IHeader {
    headerRef: any
}

const Header: FC<IHeader> = ({headerRef}) => {

    const {t} = useTranslation();

    const [currentUser, setCurrentUser]: any = useRecoilState(userState);
    const [theme] = useRecoilState(themeState);

    const navigate = useNavigate();
    const location = useLocation();
    const scrollToHandler = () => {
        if (location.pathname !== '/') {
            navigate('/')
        }
    };

    const logOutHandler = () => {
        postLogOut(setCurrentUser)
    };

    return (

        <header ref={headerRef} className={`header ${theme === 'light' && window.location.href.includes('dashboard') ? 'headerBlue' : ''}`}>
            <div className="container">
                <div className="header__main">
                    <Link to='/'>
                        {
                            window.location.href.includes('dashboard')
                                ? <LogoWhite/>
                                :
                                theme === 'light'
                                    ? <GradientLogo/>
                                    : <DarkLogo/>
                        }
                    </Link>
                    <div className="header__main_right">
                        <nav className="header__main_navbar">
                            {localStorage.getItem("token") && <Link to={"/dashboard"}>{t('dashboard')}</Link>}
                            <a onClick={scrollToHandler} href={'#solutions'}>{t('menuItem1')}</a>
                            <a onClick={scrollToHandler} href={'#products'}>{t('menuItem2')}</a>
                            <a onClick={scrollToHandler} href={'#learn'}>{t('menuItem3')}</a>
                        </nav>
                        <ul className="header__main_settings">
                            {localStorage.getItem("token")
                                ? <a onClick={logOutHandler} className="SignUp">{t('logout')}</a>
                                : <>
                                    <Link to="/login" className="Login">{t('logIn')}</Link>
                                    <Link to="/signup" className="SignUp">{t('signUp')}</Link>
                                </>
                            }
                            <div className="header__main_settings-theme">
                                <Switch/>
                            </div>
                            <LangSelect/>
                            <BurgerMenu/>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;