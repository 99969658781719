import React from 'react';
import {useParams} from 'react-router-dom';
import image1 from "../Home/Section/HomeUpdates/Card1.png";
import image2 from "../Home/Section/HomeUpdates/Card2.png";
import image3 from "../Home/Section/HomeUpdates/Card3.png";
import {useTranslation} from "react-i18next";
import './news.scss';
import Card from "../../components/Card";

const Index = () => {

    const {t} = useTranslation();

    const cards = [
        {
            title: `${t('homeUpdatesCard1Title')}`,
            desc: `${t('homeUpdatesCard1Text')}`,
            date: '10.10.2022',
            image: image1,
            id: 1
        },
        {
            title: `${t('homeUpdatesCard2Title')}`,
            desc: `${t('homeUpdatesCard2Text')}`,
            date: '17.12.2022',
            image: image2,
            id: 2
        },
        {
            title: `${t('homeUpdatesCard3Title')}`,
            desc: `${t('homeUpdatesCard3Text')}`,
            date: '23.01.2023',
            image: image3,
            id: 3
        }
    ];

    const params: any = useParams();

    const Details = cards[params.id - 1]

    return (
        <div className="News">
            <div className="container">
                <div className="News__main">
                    <h2 className="News__title">{Details.title}</h2>
                    <div className="News__info">
                        <div className="News__info_infoCard">
                            <h2>Author of the article:</h2>
                            <span>Name</span>
                        </div>
                        <div className="News__info_infoCard">
                            <h2>Category:</h2>
                            <span>News</span>
                        </div>
                        <div className="News__info_infoCard">
                            <h2>Date:</h2>
                            <span>{Details.date}</span>
                        </div>
                    </div>
                    <div className="News__description">
                        {Details.desc}
                    </div>
                </div>
                <aside className="News__aside">
                    {
                        cards.map((item) => (
                            <Card card={item}/>
                        ))
                    }
                </aside>
            </div>
        </div>
    );
};

export default Index;