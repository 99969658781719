import React from 'react';
import './HomeLatest.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import Card from "../../../../components/Card";
import image1 from './Card1.png';
import image2 from './Card2.png';
import image3 from './Card3.png';
import {useTranslation} from "react-i18next";

const Index = () => {

    const {t} = useTranslation();

    const cards = [
        {
            title: `${t('homeUpdatesCard1Title')}`,
            desc: `${t('homeUpdatesCard1Text')}`,
            date: '10.10.2022',
            image: image1,
            id: 1
        },
        {
            title: `${t('homeUpdatesCard2Title')}`,
            desc: `${t('homeUpdatesCard2Text')}`,
            date: '17.12.2022',
            image: image2,
            id: 2
        },
        {
            title: `${t('homeUpdatesCard3Title')}`,
            desc: `${t('homeUpdatesCard3Text')}`,
            date: '23.01.2023',
            image: image3,
            id: 3
        }
    ];

    return (
        <div className="HomeUpdates">
            <div className="container">
                <div className="HomeUpdates__main">
                    <h2 className="HomeUpdates__main_title">{t('homeUpdatesTitle')}</h2>
                    <div className="swiperContainer">
                        <Swiper
                            pagination={true} modules={[Pagination]}
                            breakpoints={{
                                1300: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                1050: {
                                    slidesPerView: 2.5,
                                    spaceBetween: 20
                                },
                                820: {
                                    slidesPerView: 2,
                                    spaceBetween: 50
                                },
                                768: {
                                    slidesPerView: 1.5,
                                    spaceBetween: 30
                                },
                                425: {
                                    slidesPerView: 1.3,
                                    spaceBetween: 40
                                },
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 40
                                }
                            }}
                            spaceBetween={50} slidesPerView={3}>
                            {cards ? cards.map((item) => (
                                    <SwiperSlide key={item.id}>
                                        <Card card={item}/>
                                    </SwiperSlide>
                                ))
                                : ''}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;