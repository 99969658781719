import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from '../assets/locales/en/translation';
import translationMX from '../assets/locales/mx/translation';

// the translations
const resources = {
    "Espanol": {
        translation: translationMX
    },
    "English": {
        translation: translationEN
    }
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'Espanol',
        whiteList: ["Espanol", "English"],
        debug: true,
        detection: {
            order: ["localStorage", "cookie"],
            caches: ["localStorage", "cookie"],
        },
        interpolation: {
            escapeValue: false
        },
    });


export default i18n;