import React from 'react';
import './faq.scss';
import FaqImg from '../../../../assets/images/FaqBg.png';
import ControlledAccordions from "../../../../components/Accordion";
import {useTranslation} from "react-i18next";

const HomeFaq = () => {

    const {t} = useTranslation();

    return (
        <section id="learn" className={"homeFaq"}>
            <div className="container">
                <h2 className="homeFaq__title">{t('homeFaqTitle')}</h2>
                <div className="homeFaq__row">
                    <img src={FaqImg} alt=""/>
                    <div className="homeFaq__accordion">
                        <ControlledAccordions/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeFaq;