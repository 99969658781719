import React, {useState} from 'react';
import './borrow.scss';
import './borrowAdaptive.scss';
// @ts-ignore
import Range from 'react-range-progress';

const Borrow = () => {

    const [val, setVal] = useState(495000)

    const onRangeChanged = (value: any) => {
        setVal(value)
    }

    return (
        <div className="Borrow">
            <div className="Borrow__left">
                <h2 className="Borrow__left_title">How much do you want to advance today?</h2>
                <div className="budget">$ 10,000</div>
                <Range
                    value={val}
                    trackColor={{
                        r: 15,
                        g: 91,
                        b: 59,
                        a: 0.1,
                    }}
                    min={1000}
                    max={1000000}
                    height={5}
                    width="100%"
                    onChange={(e: any) => onRangeChanged(e)}
                />
                <div className="minMax">
                    <div>
                        <span>min</span>
                        <span>$ 1,000</span>
                    </div>
                    <div>
                        <span>max</span>
                        <span>$ 1,000,000</span>
                    </div>
                </div>
            </div>

            <div className="Borrow__right">
                <div>
                    <span className="info">Cash received today</span>
                    <h2 className="cash">$ 100,000</h2>
                </div>
                <div>
                    <span className="info">Effective cost</span>
                    <h2 className="cash">10.0%</h2>
                </div>
                <hr/>
                <div>
                    <div className="info">Total cost <div className="question">?</div></div>
                    <h2 className="cash">$ 10,000</h2>
                </div>
            </div>
        </div>
    );
};

export default Borrow;