import React from 'react';
import './history.scss';
import './histroyAdaptive.scss';

const History = () => {
    return (
        <div className="dashboardHistory">
            <div className="dashboardHistory__box">
                <div className="dashboardHistory__box_head">
                    <p className="dashboardHistory__box_head_item">amount</p>
                    <p className="dashboardHistory__box_head_item">requested</p>
                    <p className="dashboardHistory__box_head_item">paid on</p>
                    <p className="dashboardHistory__box_head_item">status</p>
                </div>
                <div className="dashboardHistory__box_body">
                    <div className="dashboardHistory__box_body_row">
                        <div className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-1">
                            <p>$ 100,000</p>
                            <span>Lewis Harper</span>
                        </div>
                        <p className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-2">Mar 20, 2022</p>
                        <p className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-3">-</p>
                        <div className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-4">
                            <button>SENT</button>
                        </div>
                    </div>
                    <div className="dashboardHistory__box_body_row">
                        <div className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-1">
                            <p>$ 100,000</p>
                            <span>Lewis Harper</span>
                        </div>
                        <p className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-2">Mar 20, 2022</p>
                        <p className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-3">-</p>
                        <div className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-4">
                            <button>SENT</button>
                        </div>
                    </div>
                    <div className="dashboardHistory__box_body_row">
                        <div className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-1">
                            <p>$ 100,000</p>
                            <span>Lewis Harper</span>
                        </div>
                        <p className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-2">Mar 20, 2022</p>
                        <p className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-3">-</p>
                        <div className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-4">
                            <button>SENT</button>
                        </div>
                    </div>
                    <div className="dashboardHistory__box_body_row">
                        <div className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-1">
                            <p>$ 100,000</p>
                            <span>Lewis Harper</span>
                        </div>
                        <p className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-2">Mar 20, 2022</p>
                        <p className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-3">-</p>
                        <div className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-4">
                            <button>SENT</button>
                        </div>
                    </div>
                    <div className="dashboardHistory__box_body_row">
                        <div className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-1">
                            <p>$ 100,000</p>
                            <span>Lewis Harper</span>
                        </div>
                        <p className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-2">Mar 20, 2022</p>
                        <p className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-3">-</p>
                        <div className="dashboardHistory__box_body_row-item dashboardHistory__box_body_row-item-4">
                            <button>SENT</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default History;