import React from 'react';
import './footer.scss';
import Logo from "../../components/Logo";
import ArrowUp from "../../components/Icons/ArrowUp";
import TwitterIcon from "../../components/Icons/TwitterIcon";
import LinkedinIcon from "../../components/Icons/LinkedinIcon";
import EmailIcon from "../../components/Icons/EmailIcon";
import {Link} from "react-router-dom";

import {useTranslation} from "react-i18next";

const Index = () => {

    const {t} = useTranslation();

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__main">
                    <div className="footer__main_up">
                        <div className="footer__main_up-left">
                            <Link to='/'>
                                <Logo/>
                            </Link>
                            <a href={'#solutions'}>{t('menuItem1')}</a>
                            <a href={'#products'}>{t('menuItem2')}</a>
                            <a href={'#learn'}>{t('menuItem3')}</a>
                        </div>
                        <div className="footer__main_up-right">
                            <ArrowUp/>
                        </div>
                    </div>
                    <div className="footer__main_down">
                        <div className="footer__main_down-up">
                            <div className="terms">
                                <span>{t('footerMadeBy')} <span className={"heart"}>❤</span> {t('footerMadeBy2')}</span>
                                <span>{t('footerRules')}</span>
                                <span>{t('footerTerms')}</span>
                            </div>
                            <div>
                                <TwitterIcon/>
                                <LinkedinIcon/>
                                <EmailIcon/>
                            </div>
                        </div>
                        <div className="footer__main_down-down">
                            <span>
                                {t('footerText')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Index;