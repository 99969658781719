import React, {useEffect, useRef} from 'react';
import './index.css';
import 'remixicon/fonts/remixicon.css'
import {Route, Routes, useLocation} from "react-router";
import Home from "./pages/Home";
import Header from "./shared/Header";
import './utils/i18';
import {useRecoilState} from "recoil";
import {BurgerState, HeaderHeightState, modal, themeState} from "./store/states";
import './assets/styles/DarkTheme/Dark.scss'
import Login from "./pages/Login";
import Layout from "./components/Layout";
import LoginLayout from "./components/LoginLayout";
import SignUp from "./pages/SignUp";
import Details from "./pages/News";
import RequireAuth from "./components/hoc/RequireAuth";
import Dashboard from "./pages/dashboard";
import Authed from "./components/hoc/Authed";
import ModalWindow from "./components/ModalWindow";
import {getUserData} from "./utils/firebase";
import {userState} from "./store/user";
import {useNavigate} from "react-router-dom";
import JoinModal from "./pages/JoinModal";


function App() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname]);

    const [theme, setTheme] = useRecoilState(themeState);
    const [headerHeight, setHeaderHeight] = useRecoilState(HeaderHeightState);
    const [currentUser, setCurrentUser] = useRecoilState(userState);
    const [stateModal, setModalState] = useRecoilState(modal);

    const navigate = useNavigate();
    const [burger] = useRecoilState(BurgerState);

    useEffect(() => {
        const localTheme: any = localStorage.getItem("theme");
        if (localTheme) {
            setTheme(localTheme)
        }
        const userId: any = localStorage.getItem('uid')
        const token: any = localStorage.getItem('token')

        if (userId && token) {
            getUserData(userId, setCurrentUser, setModalState, navigate)
        }
    }, []);

    const headerRef: any = useRef();

    useEffect(() => {
        if (headerRef?.current?.clientHeight) {
            setHeaderHeight(headerRef.current.clientHeight)
        }
    }, [headerRef]);

    return (
        <div className={`app ${theme === 'dark' ? 'darkTheme' : ''} ${burger ? 'hidden' : ''}`}>
            {
                modal ?
                    <ModalWindow/>
                    : ''
            }
            <Header headerRef={headerRef}/>
            <Routes>
                <Route path={"/"} element={<Layout/>}>
                    <Route index element={<Home/>}/>
                </Route>
                <Route path={"/"} element={
                    <Authed>
                        <LoginLayout/>
                    </Authed>
                }>
                    <Route path={"/login"} element={<Login/>}/>
                    <Route path={"/signup"} element={<SignUp/>}/>
                </Route>
                <Route path={"/news/:id"} element={<Details/>}/>
                <Route path={"/dashboard"} element={
                    <RequireAuth>
                        <Dashboard/>
                    </RequireAuth>
                }/>
                <Route path={"/join"} element={<Authed>
                    <JoinModal/>
                </Authed>}/>
            </Routes>
        </div>
    );
}

export default App;
