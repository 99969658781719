import React from 'react';

interface Interface {
    state: boolean
}

const SelectorArrow: React.FC<Interface> = ({state}) => {
    return (
        <svg style={{transform: `${state ? 'rotate(-180deg)' : ''}`, transition: '0.4s'}} width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L9 9L17 1" stroke="#0F3B5B" strokeWidth="2" strokeLinejoin="round"/>
        </svg>

    );
};

export default SelectorArrow;