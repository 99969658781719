import React, {useEffect, useState} from 'react';
import '../Login/login.scss'
import LoginInput from "../../components/LoginInput";
import {Link, useNavigate} from "react-router-dom";
import Button from "../../components/Button";
import LoginSocialMedia from "../../components/LoginSocialMedia";
import {getUserData, postRegisterUser} from "../../utils/firebase";
import {useRecoilState} from "recoil";
import {userState} from "../../store/user";
import {child, get, getDatabase, ref} from "firebase/database";
import {useTranslation} from "react-i18next";
import {modal} from "../../store/states";

const SignUp = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser]: any = useRecoilState(userState);
    const [modalState, setModalState] = useRecoilState(modal)

    const navigateHandler = () => {
        navigate('/join')
    };

    useEffect(() => {
    }, [currentUser]);

    const signUpHandler = (e: any) => {
        e.preventDefault();
        postRegisterUser(e.target[0].value, e.target[1].value, e.target[2].value, e.target[3].value, e.target[4].value, e.target[5].value)
            .then((user: any) => {
                navigateHandler();
            }).catch(() => {
        })
    };

    return (
        <>
            <h2 className="login__title">{t('signUp')}</h2>
            <form onSubmit={(e) => signUpHandler(e)} className="login__form">
                <LoginInput name={'firstName'} type={true} placeholder={t("firstName")}/>
                <LoginInput name={'lastName'} type={true} placeholder={t("lastName")}/>
                <LoginInput name={'companyName'} type={true} placeholder={t("companyName")}/>
                <LoginInput name={'email'} type={true} placeholder={t("emailAddress")}/>
                <LoginInput name={'phone'} type={true} placeholder={t("telephone")}/>
                <LoginInput name={'password'} type={false} placeholder={t("password")}/>
                <Button type={2} text={t('signUpBtn')}/>
            </form>
            <p className="login__alt">*{t('signUpSubtitle')} <br/> <Link to={"/terms"}>{t('terms')}</Link> {t('and')}
                <Link
                    to={"/privacy"}> {t('privacy')}.</Link></p>
            {/*<LoginSocialMedia/>*/}
            <div className="login__action login__haveAcc">
                <p className="login__action_text">{t('haveAcc')}?</p>
                <Link to={"/login"} className="login__action_text">{t('logIn2')}</Link>
            </div>
        </>
    );
};

export default SignUp;