import React from 'react';
import './HomeBenefits.scss';
import {useTranslation} from "react-i18next";

const Index = () => {
    const {t} = useTranslation();
    return (
        <div id="products" className="HomeBenefits">
            <div className="container">
                <div className="HomeBenefits__main">
                    <h2 className="HomeBenefits__main_title">{t("homeBenefitsTitle")}</h2>
                    <span className="HomeBenefits__main_subtitle">{t("homeBenefitsText")}</span>
                    <div className="HomeBenefits__main_benefitCards">
                        <div className="HomeBenefits__main_benefitCards-card">
                            <span className="HomeBenefits__main_benefitCards-number">1</span>
                            <div className="HomeBenefits__main_benefitCards-texts">
                                <h2>{t('homeBenefitsCard1TitleFirst')}<br/>{t('homeBenefitsCard1TitleSecond')}</h2>
                                <span>{t('homeBenefitsCard1Subtitle')}</span>
                            </div>
                        </div>
                        <div className="HomeBenefits__main_benefitCards-card">
                            <span className="HomeBenefits__main_benefitCards-number">2</span>
                            <div className="HomeBenefits__main_benefitCards-texts">
                                <h2>{t('homeBenefitsCard2TitleFirst')}<br/>{t('homeBenefitsCard2TitleSecond')}</h2>
                                <span>{t('homeBenefitsCard2Subtitle')}</span>
                            </div>
                        </div>
                        <div className="HomeBenefits__main_benefitCards-card">
                            <span className="HomeBenefits__main_benefitCards-number">3</span>
                            <div className="HomeBenefits__main_benefitCards-texts">
                                <h2>{t('homeBenefitsCard3TitleFirst')}<br/>{t('homeBenefitsCard3TitleSecond')}</h2>
                                <span>{t('homeBenefitsCard3Subtitle')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;