import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {RecoilRoot} from "recoil";

import './assets/styles/Adaptive/1440.scss';
import './assets/styles/Adaptive/1200.scss';
import './assets/styles/Adaptive/930.scss';
import './assets/styles/Adaptive/768.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <RecoilRoot>
                <App/>
            </RecoilRoot>
        </BrowserRouter>
    </React.StrictMode>
);

