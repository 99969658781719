import React from 'react';

const Index = () => {
    return (
        <svg onClick={()=>{
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }} width="16" height="54" viewBox="0 0 16 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 53C7 53.5523 7.44771 54 8 54C8.55228 54 9 53.5523 9 53L7 53ZM8.70712 0.792892C8.3166 0.40237 7.68344 0.40237 7.29291 0.792892L0.928948 7.15685C0.538423 7.54738 0.538423 8.18054 0.928947 8.57106C1.31947 8.96159 1.95264 8.96159 2.34316 8.57106L8.00002 2.91422L13.6569 8.57107C14.0474 8.96159 14.6806 8.96159 15.0711 8.57107C15.4616 8.18055 15.4616 7.54738 15.0711 7.15686L8.70712 0.792892ZM9 53L9.00002 1.5L7.00002 1.5L7 53L9 53Z"
                fill="white"/>
        </svg>

    );
};

export default Index;