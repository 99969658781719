import React from 'react';
import './Submit.scss';
import Saly from '../../../../assets/images/Saly.png'
import Button from "../../../../components/Button";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Index = () => {
    const {t} = useTranslation();
    return (
        <div className="submitEmail">
            <div className="container">
                <div className="submitEmail__main">
                    <div className="submitEmail__main_left">
                        <img src={Saly} alt=""/>
                    </div>
                    <div className="submitEmail__main_right">
                        <h2 className="title title-upper">{t('homeSubmitTitle')}</h2>
                        <h2 className="title">
                            {t('homeSubmitSubtitleFirst')} <span>{t('homeSubmitSubtitleSecond')}</span> {t('homeSubmitSubtitleThird')}
                        </h2>
                        <span className="subtitle">{t("homeSubmitText")}</span>
                        {/*<form>*/}
                        {/*    <label>*/}
                        {/*        <input placeholder={t('inputEmail')} type="text"/>*/}
                        {/*        <Button type={2} text={t('joinButton')}/>*/}
                        {/*    </label>*/}
                        {/*</form>*/}
                        <Link to={"/signup"} className="submitEmail__button">
                            <Button type={2} text={t('joinButton')}/>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Index;